<script setup>
import Header from './components/Header/index.vue'
import Loading from './components/Loading.vue'
import { watch, provide, ref, onMounted, computed, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { pxToPx } from './utils/pxToRem'
import { getSearch, isWechat } from './utils/utils'
import { path } from '@/router/constant'
import useCheckReport from './hooks/useCheckReport.js'
import useInit from '@/hooks/useInit'
import useSkipPay from './hooks/useSkipPay.js'
import useReportType from '@/hooks/useReportType'
import mitt from './plugins/mitt'
import { UPDATE_ROUTER } from './utils/contants'
import useSetUnit from './hooks/useSetUnit'
import storage from './utils/storage'

const { setRemUnit, mountListener, unmountListener } = useSetUnit()
const route = useRoute()

const isPC = ref(false)
const loading = ref(false)
const height = ref(window.innerHeight)
const headerRef = ref()
const router = useRouter()
const loadingRef = ref(false)

const showHeader = computed(() => {
  const search = getSearch() || {}

  return (
    search['from'] !== 'email' &&
    ![path.fresh, path.freshReport, path.freshReportDetail].includes(route.path)
  ) && !search['open'] && !storage.get('open')
})

const _height = computed(() => {
  console.log(height.value)
  console.log(pxToPx(178))

  if (!showHeader.value) return height.value + 'px'
  return height.value - pxToPx(178) + 'px'
})

const [, first] = location.pathname.split('/')
if (
  ['birth', 'person', 'destiny', 'love', 'relation', 'profession'].includes(
    first
  )
)
  useReportType()

provide('closeMenu', () => {
  headerRef.value.close()
})
provide('openMenu', (...rest) => {
  headerRef.value.openMenu(...rest)
})

// 更新布局配置
mitt.on(UPDATE_ROUTER, (route) => {
  if (/\/pc\//.test(route)) {
    isPC.value = true
    unmountListener()
    document.documentElement.style.fontSize = '12px'
  } else {
    isPC.value = false
    setRemUnit()
    mountListener()
  }
})

// 高度变化
watch(
  () => height.value,
  (newV) => {
    document.documentElement.style.setProperty('--vh', `${newV}px`)
  },
  {
    immediate: true
  }
)

watch(
  () => isPC.value,
  (newV) => {
    loading.value = true
    setTimeout(() => {
      loading.value = false
    }, 1000)
  }
)

router.beforeEach(() => {
  loadingRef.value.show(true)
})
router.afterEach(() => {
  loadingRef.value.show(false)
})

onMounted(() => {
  useCheckReport()
  // 触发resize
})
window.addEventListener('pageshow', () => {
  setTimeout(() => {
    height.value = window.innerHeight
  }, 500)
})
useSkipPay()
useInit(height)
onUnmounted(() => {
  storage.remove('open')
})
</script>

<template>
  <div class="app-loading" v-if="loading">
    <div class="rotate"></div>
  </div>
  <div id="mobile" v-if="!isPC">
    <header class="header-fixed max-width" v-if="showHeader">
      <Header ref="headerRef" />
    </header>
    <section class="content max-width">
      <RouterView />
    </section>
  </div>
  <div class="pc" v-else>
    <RouterView />
  </div>
  <Loading ref="loadingRef" background="rgba(0, 0, 0, 0.1)" />
</template>

<style scoped lang="less">
.header-fixed {
  position: relative;
  z-index: 3;
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  height: v-bind(_height);
}

.app-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 4;
  .rotate {
    position: relative;
    width: 1em;
    height: 1em;
    // prettier-ignore
    font-size: 8Px;
    text-indent: -9999em;
    border-radius: 50%;
    animation: mulShdSpin 1s infinite linear;
    transform: translateZ(0);
    color: #a48fff;

    @keyframes mulShdSpin {
      0%,
      100% {
        box-shadow: 0 -3em 0 0.1em, 2em -2em 0 0.2em, 3em 0 0 0.4em,
          2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
          -2em -2em 0 0;
      }

      12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0.3em, 2em 2em 0 0.4em,
          0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
      }

      25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em,
          2em 2em 0 0.3em, 0 3em 0 0.4em, -2em 2em 0 -1em, -3em 0 0 -1em,
          -2em -2em 0 -1em;
      }

      37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
          0 3em 0 0.3em, -2em 2em 0 0.4em, -3em 0em 0 -1em, -2em -2em 0 -1em;
      }

      50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
          0 3em 0 0.2em, -2em 2em 0 0.3em, -3em 0em 0 0.4em, -2em -2em 0 -1em;
      }

      62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
          0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0.3em, -2em -2em 0 0.4em;
      }

      75% {
        box-shadow: 0em -3em 0 0.4em, 2em -2em 0 -1em, 3em 0em 0 -1em,
          2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
          -2em -2em 0 0.3em;
      }

      87.5% {
        box-shadow: 0em -3em 0 0.3em, 2em -2em 0 0.4em, 3em 0 0 -1em,
          2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
          -2em -2em 0 0.2em;
      }
    }
  }
}
</style>
