<template>
  <animation position="left">
    <div class="order--wrapper" v-if="visible">
      <div class="order-header">
        <van-icon
          class="left"
          name="arrow-left"
          :size="pxToPx(80)"
          @click="show(false)"
        />
        <span class="title">{{ $t('menu.order_search') }}</span>
        <van-icon
          name="question-o"
          color="#5193FF"
          :size="pxToPx(64)"
          @click="help"
        />
      </div>
      <div class="order-content">
        <div class="input-wrapper">
          <div class="tips">{{ $t('menu.order_tips') }}</div>
          <div class="input">
            <svg-icon class="search" name="public-search" size="72"></svg-icon>
            <input type="text" v-model="keyword" />
            <van-button class="button" :loading="searching" @click="search">{{
              $t('search')
            }}</van-button>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="title" v-if="reportList">
            <div class="type">{{ $t('menu.order_type') }}</div>
            <div class="name">{{ $t('menu.order_name') }}</div>
            <div class="operate">{{ $t('menu.operate') }}</div>
          </div>
          <ul class="list" v-if="reportList" @touchmove.stop="">
            <li class="report" v-for="report in reportList">
              <div class="type">
                <span>{{ report.typeName }}</span>
              </div>
              <div class="name">
                <span v-html="report.name"></span>
              </div>
              <div class="operate" @click="check(report)">
                {{ $t('menu.detail') }} >>
              </div>
            </li>
            <li v-if="!reportList.length" class="no-item">
              <span>{{ $t('menu.no_result') }}</span>
            </li>
          </ul>
          <Contact />
        </div>
      </div>
      <animation position="fade">
        <preview
          ref="helpRef"
          background="rgba(0,0,0,.7)"
          :closeable="false"
          @mask-click="closeHelp"
        >
          <div class="help-modal-wrapper">
            <svg-icon
              class="close"
              name="report-love-close"
              size="72"
              @click="closeHelp"
            ></svg-icon>
            <div class="item" v-for="(item, idx) in helpList" :key="item">
              <div class="title">{{ idx + 1 }}</div>
              <div class="content" v-html="item"></div>
            </div>
          </div>
        </preview>
      </animation>
    </div>
  </animation>
</template>

<script setup>
import { ref, computed, inject, getCurrentInstance, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { pxToPx } from '@/utils/pxToRem'
import { path } from '@/router/constant'
import Animation from '@/components/Animation.vue'
import Preview from '@/components/Preview.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Contact from './Contact.vue'
import { orderSearch } from '@/apis/report'
import {
  REPORT_TYPE,
  LANG,
  WECHAT_ID,
  EMAIL,
  UPDATE_ANSWER,
  PRODUCT_TYPE,
  ANSWER_INFO
} from '@/utils/contants'
import { isLang } from '@/utils/utils'
import storage from '@/utils/storage'

const pathList = {
  3: {
    1: path.match.detail,
    2: path.match.detail,
    3: path.match.detail,
    4: path.siblings.detail,
    5: path.siblings.detail,
    6: path.workplace.detail,
    7: path.workplace.detail,
    8: path.workplace.detail,
  },
  4: [
    path.birth.detail,
    path.personality.detail,
    path.destiny.detail,
    path.love.detail,
    path.relation.detail,
    path.profession.detail
  ],
  5: path.answer.detail,
  7: path.hexagram.detail,
  8: path.annual.detail,
  9: ['', path.enterprise.detail, path.career.detail],
  11: path.academic.detail,
  12: path.luck.detail,
  13: path.naming.detail
}
const nameList = {
  3: {
    0: 'menu.match',
    1: 'menu.match',
    2: 'menu.match',
    3: 'menu.match',
    4: 'siblings.title_short',
    5: 'siblings.title_short',
    6: 'workplace.title_short',
    7: 'workplace.title_short',
    8: 'workplace.title_short'
  },
  4: [
    'menu.entire',
    'menu.person',
    'menu.destiny',
    'menu.love',
    'menu.relation',
    'menu.profession'
  ],
  5: 'menu.answer',
  7: 'menu.hexagram',
  8: 'menu.annual',
  9: ['', 'menu.enterprise', 'career.title'],
  11: 'menu.academic',
  12: 'luck.title',
  13: 'naming.title'
}

const { mitt } = getCurrentInstance().proxy
const closeMenu = inject('closeMenu')
const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const keyword = ref('')
const helpRef = ref(null)
const visible = ref(false)
const searching = ref(false)
const reportList = ref([])

const helpList = computed(() => i18n.tm('report.save.modal_success'))

const help = () => {
  helpRef.value.preview()
}

const closeHelp = () => {
  helpRef.value.preview(false)
}

const show = (value = true) => {
  visible.value = value
}

const search = () => {
  searching.value = true
  gtag('event', 'searchrecords_click_search')
  orderSearch({ q: keyword.value })
    .then((res) => {
      reportList.value = res.data?.map((item) => {
        const { type, rType = 0, fn, sn } = item
        const nameKey =
          typeof nameList[type] === 'string'
            ? nameList[type]
            : nameList[type][rType || 0]
        const name =
          type === 3 ? `${fn}${rType < 4 ? '&hearts;' : 'x'}${sn}` : item.name

        return {
          ...item,
          typeName: i18n.t(nameKey),
          name
        }
      })
    })
    .finally(() => {
      searching.value = false
    })
}

const checkReport = (report) => {
  const { s, rType = 0, type = 0 } = report
  const p = pathList[type]
  const _path = typeof p === 'string' ? p : p[rType]
  storage.set('s', s)
  storage.set(REPORT_TYPE, rType)
  if (route.path.includes(_path)) {
    // 支付页跳转
    if (/payment|simple/i.test(route.path)) router.replace(_path)
    else router.push({ path: _path })
    mitt.emit('updateS', 'search')
  } else
    router.push({
      path: _path,
      query: {
        from: 'search'
      }
    })

  closeMenu()
}

const checkAnwser = (answer) => {
  const { orderNo, rid, did } = answer
  storage.set(ANSWER_INFO, { answerId: rid, answer, save: true, did })
  if (route.path.includes(path.answer.detail)) {
    router.push({
      path: path.answer.detail,
      query: {
        orderNo
      }
    })
    setTimeout(() => {
      mitt.emit(UPDATE_ANSWER)
    })
  } else {
    router.push({
      path: path.answer.detail,
      query: {
        orderNo
      }
    })
  }
  closeMenu()
}

const check = (info) => {
  const { type } = info
  if (type === PRODUCT_TYPE.divine) checkAnwser(info)
  else checkReport(info)
}

watch(
  () => visible.value,
  (visible) => {
    if (visible) {
      gtag('event', 'searchrecords_view')
    }
  }
)

defineExpose({
  show
})
</script>

<style lang="less" scoped>
@import '@/assets/style/variable.less';
@import '@/assets/style/main.less';

.order--wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  background-color: #fff;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .order-header {
    width: 100%;
    max-width: @maxWidth;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    // align-items: center;
    height: 284px;
    width: 100%;
    text-align: center;

    .left {
      position: absolute;
      left: 60px;
      top: 50%;
      transform: translateY(-50%);
    }

    .title {
      font-size: 72px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }

  .order-content {
    height: calc(100% - 284px);

    .input-wrapper {
      .tips {
        text-align: center;
        font-size: 52px;
        color: #666666;
      }

      .input {
        box-sizing: border-box;
        padding: 0 16px 0 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 42px;
        width: 1270px;
        height: 152px;
        background: #f5f5f5;
        border-radius: 32px;

        & > input {
          padding: 0 2em;
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: 60px;
          border: 0;
          background-color: transparent;
        }

        .search {
          flex-shrink: 0;
        }

        .button {
          flex-shrink: 0;
          width: 178px;
          height: 100px;
          white-space: nowrap;
          text-align: center;
          font-size: 52px;
          color: #ffffff;
          background: #5193ff;
          border-radius: 16px;
        }
      }
    }

    .table-wrapper {
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - 246px);
      padding-top: 66px;
      font-size: 52px;
      display: flex;
      flex-direction: column;

      .title {
        padding-top: 17px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }

      .list {
        min-height: 710px;
        height: 100%;
        font-size: 52px;
        overflow-y: auto;

        .report {
          display: flex;
          justify-content: space-between;
          padding: 45px 0;

          .name {
            & > span {
              display: inline-block;
              width: 5em;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .operate {
            color: #5193ff;
          }
        }

        .no-item {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .type,
      .name,
      .operate {
        width: 33%;
        text-align: center;
      }

      :deep(.contact--wrapper) {
        margin-top: auto;
      }
    }
  }

  .help-modal-wrapper {
    position: relative;
    padding: 96px 40px;
    width: 1244px;
    background-color: #fff;
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.15);
    border-radius: 80px;

    .close {
      position: absolute;
      right: 60px;
      top: 60px;
    }

    .item {
      margin-top: 42px;

      .title {
        margin: 0 auto;
        width: 96px;
        height: 96px;
        line-height: 96px;
        font-size: 56px;
        font-weight: bold;
        text-align: center;
        background: #ffd76b;
        border-radius: 55px;
      }

      .content {
        margin-top: 48px;
        padding: 96px 74px;
        font-size: 56px;
        line-height: 92px;
        background: #f8f8f8;
        border-radius: 8px;

        :deep(span) {
          color: #f44336;
        }
      }
    }
  }

  @media screen and (max-width: 759px) {
    height: calc(var(--vh) - @menuHeight);
  }
}
</style>
