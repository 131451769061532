import { watch } from 'vue'
import { useRoute } from 'vue-router'
import storage from '@/utils/storage'
import { getArea, checkBind, statistics } from '@/apis/report'
import {
  CURRENCY,
  country2Currency,
  DEFAULT_COUNTRY,
  FAN_NO,
  FAN_TYPE,
  ANNUAL_PRICE,
  ANSWER_PROD,
  MATCH_PRICE,
  ENTERPRISE_PRICE,
  ACADEMIC_PRICE,
  NAMEING_PRICE,
  LUCK_PRICE
} from '@/utils/contants'
import { getSearch, isWechat } from '@/utils/utils'
import useSkipPay from './useSkipPay'

// 粉丝链接保存订单号
export const checkFans = async () => {
  const { orderNo, type } = getSearch()
  const route = useRoute()
  if (orderNo && !type) {
    await checkBind({ orderNo }).then((res) => {
      const { data } = res
      if (!data.res) {
        console.log(route.path)
        setTimeout(() => {
          storage.set(FAN_NO, orderNo)
          storage.set(FAN_TYPE, route.path)
        }, 0)
      }
    })
  } else {
    const fanNo = storage.get(FAN_NO)
    if (fanNo) {
      await checkBind({ orderNo: fanNo }).then((res) => {
        const { data } = res
        if (data.res) storage.delete(FAN_NO)
      })
    }
  }
}

// 初始化货币
const getCurrency = () => {
  const currency = getSearch()[CURRENCY] || null
  if (currency) storage.set(CURRENCY, currency)
  else if (!storage.get(CURRENCY)) {
    getArea()
      .then((res) => {
        console.log(res)
        const { country = DEFAULT_COUNTRY } = res.data
        country && storage.set(CURRENCY, country2Currency[country])
      })
      .catch((err) => {
        storage.set(CURRENCY, country2Currency[DEFAULT_COUNTRY])
      })
  }
}

// 统计数据
const statis = () => {
  // console.log(route)
  const { t } = getSearch()
  if (!t) return
  storage.set('t', t)
  // const
  statistics({ t })
}

// 推广数据初始化
const initAdData = () => {
  const { a, e, g, p, product, ac, n, l } = getSearch()
  // 宫合报告
  if (product) storage.set(MATCH_PRICE, product)
  // 生日报告
  if (g) storage.set('g', g)
  // 答案之书 product
  if (p) storage.set(ANSWER_PROD, p)
  // 年运详批价格
  if (a) storage.set(ANNUAL_PRICE, a)
  // 职能报告企业版
  if (e) storage.set(ENTERPRISE_PRICE, e)
  // 学业报告
  if (ac) storage.set(ACADEMIC_PRICE, ac)
  // 起名报告
  if (n) storage.set(NAMEING_PRICE, n)
  // 定制择吉
  if (l) storage.set(LUCK_PRICE, l)
}

// 滚动到页面顶部
const scrollTop = () => {
  const contentBox = document.querySelector('#app > .content')
  contentBox?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export default function init(height) {
  const route = useRoute()
  const search = getSearch() || {}
  if (search.open) {
    storage.set('open', search.open)
  }

  checkFans()
  getCurrency()
  statis()
  initAdData()
  useSkipPay()

  watch(
    () => route.path,
    () => {
      setTimeout(scrollTop, 100)
      if (isWechat()) height.value = window.innerHeight
    }
  )
}
