<template>
  <div class="luck-list">
    <div class="title">
      <div class="type">{{ $t('luck.item1') }}</div>
      <div class="operate">{{ $t('menu.operate') }}</div>
    </div>
    <ul class="list" @touchmove.stop="">
      <li class="report" v-for="report in list">
        <div class="type">
          <span>{{ report.subject }}</span>
        </div>
        <div class="operate" @click="checkReport(report)">
          {{ $t('menu.detail') }} >>
        </div>
      </li>
    </ul>
    <div class="order-button" @click="openOrder">{{ $t('menu.no_order') }}</div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { getLuck } from '@/utils/menu'
import storage from '@/utils/storage'
import mitt from '@/plugins/mitt'
import { useRoute, useRouter } from 'vue-router'
import { path } from '@/router/constant'
import { ORDER_MENU } from '@/utils/contants'

const route = useRoute()
const router = useRouter()
const closeMenu = inject('closeMenu')

const list = getLuck()

const checkReport = (report) => {
  const { s } = report
  console.log(report)
  storage.set('s', s)
  if (route.path === path.luck.detail) {
    mitt.emit('updateS', report)
  } else {
    router.push(path.luck.detail)
  }
  closeMenu()
}

const openOrder = () => {
  mitt.emit(ORDER_MENU)
}
</script>

<style lang="less" scoped>
.luck-list {
  height: 100%;
  padding-top: 66px;
  font-size: 52px;

  .title {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .type,
  .operate {
    width: 50%;
    text-align: center;
  }

  .list {
    max-height: 1420px;
    height: 100%;
    font-size: 52px;
    overflow-y: auto;

    .report {
      display: flex;
      justify-content: space-between;
      padding: 45px 0;

      .type {
        & > span {
          display: inline-block;
          width: 5em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .operate {
        color: #5193ff;
      }
    }

    .no-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .order-button {
    margin: 0 auto;
    margin-top: 50px;
    width: 932px;
    height: 181px;
    line-height: 181px;
    font-size: 72px;
    color: #fff;
    background: #5193ff;
    border-radius: 119px;
    text-align: center;
  }
}
</style>
